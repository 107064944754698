<template>
  <HeroHeader
    title="Licenças"
    subtitle="Gerencie as licenças do Abaccus Decision"
  ></HeroHeader>
  <div class="container is-flex is-flex-direction-column">
    <TabelaLicencas
      :licencas="licencas"
      v-if="licencas.length"
    ></TabelaLicencas>
    <button class="button is-primary is-align-self-flex-start mt-3" disabled>
      Nova Licença
    </button>
  </div>
</template>
<script lang="ts" setup>
  import HeroHeader from '@/components/HeroHeader.vue';
  import TabelaLicencas from '@/components/TabelaLicencas.vue';
  import ILicenca from '@/interfaces/ILicenca';
  import { useStore } from '@/store';
  import axios from 'axios';
  import { Ref, onBeforeMount, ref } from 'vue';

  const store = useStore();
  const licencas: Ref<ILicenca[]> = ref([]);

  onBeforeMount(() => {
    getLicencas();
  });

  function getLicencas(): void {
    store.commit('showLoading');
    axios
      .get('https://cert.abaccusapi.com.br/v2/painel')
      .then(resp => {
        montaLicencas(resp.data);
        store.commit('hideLoading');
      })
      .catch(err => console.error(err));
  }
  function montaLicencas(data: ILicenca[]): void {
    data.forEach(licenca => {
      licenca.qtdObjetos = Object.entries(licenca.objetos).length,
      licencas.value.push(licenca);
    });
  }
</script>
